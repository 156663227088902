<template>
	<img src="./assets/profiles/profile.png" alt="lone64">
	
  <h1>Lone64</h1>
  <h2>The Minecraft Plugin and Python Backend Developer.</h2>

  <div class="icons">
    <a href="https://github.com/devlone64" target="_blank">
      <img src="./assets/icons/github.png" alt="GitHub">
    </a>
    <a href="https://gitlab.com/lone64" target="_blank">
      <img src="./assets/icons/gitlab.png" alt="GitLab">
    </a>
    <a href="https://youtube.com/@lone64dev" target="_blank">
      <img src="./assets/icons/youtube.png" alt="YouTube">
    </a>
  </div>
</template>

<script>
export default {
  name: "App",
  created() {
    document.title = "Lone64";
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 60px;
}

body {
  margin: 0;
  background-color: #2b2b2b;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

img {
  width: 192px;
  height: 192px;
  border-radius: 50%;
}

h1 {
  font-size: 40px;
  margin: 0px 0 -11px 0;
}

h2 {
  font-size: 20px;
  margin-bottom: 30px;
  color: #ccc;
}

.icons {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.icons a img {
  width: 48px;
  height: 48px;
}

.icons a:hover img {
  opacity: 0.7;
}

@media (max-width: 768px) {
  img {
    width: 128px;
    height: 128px;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 18px;
  }

  .icons a img {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 480px) {
  img {
    width: 96px;
    height: 96px;
  }

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 16px;
  }

  .icons a img {
    width: 32px;
    height: 32px;
  }
}
</style>
